export default (allBrowsers: boolean = false) => {
  if (
    typeof window !== "undefined" &&
    window.navigator &&
    window.navigator.userAgent
  ) {
    const ua = window.navigator.userAgent;
    const iOS =
      (!!ua.match(/iPad/i) || !ua.match(/iPhone/i)) && !!ua.match(/WebKit/i);
    return (
      iOS &&
      !!ua.match(/Safari/i) &&
      (allBrowsers || !ua.match(/CriOS/i)) &&
      (allBrowsers || !ua.match(/Chrome/i))
    );
  }
  return false;
};

export const getDeviceOS = () => {
  if (typeof window === "undefined") return undefined;

  const { userAgent } = window.navigator;

  if (/android/i.test(userAgent)) {
    return "android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return "iOS";
  }

  return undefined;
};
