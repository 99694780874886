import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import type { States } from "@tvg/ts-types/States";
import type { NullaryFn } from "@tvg/ts-types/Functional";
import {
  clearSportsbookRegionCookie,
  createSportsbookRegionCookie,
  getSportsbookRegionCookie
} from "@tvg/sh-utils/sessionUtils";
import {
  setGeoLocationState,
  setShowStateSelector
} from "@tvg/sh-geolocation/src/utils/requestGeolocation";
import { getIsLogged } from "@urp/store-selectors";
import { resetStateLocationSplashAction } from "@urp/location-splash/src/actions";
import { triggerGlobalCallback } from "@tvg/sh-utils/callbackUtils";
import { isShowingStateSelector } from "@tvg/sh-geolocation/src/redux/selectors";
import { setCoreConfigState } from "@urp/unified-modules/src/redux/actions/coreConfig";

import StateSelector from ".";

interface Props {
  currentPath?: string;
  onClose?: NullaryFn<void>;
}

const StateSelectorScreen = ({ currentPath, onClose }: Props) => {
  const dispatch = useDispatch();
  const userLogged = useSelector(getIsLogged);
  const showStateSelector = useSelector(isShowingStateSelector);
  const [element, setElement] = useState<HTMLDivElement | null>(null);
  const [root, setRoot] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setElement(window.document.createElement("div"));
      setRoot(window.document.body);
    }
  }, []);

  useEffect(() => {
    if (element && root) {
      root.appendChild(element);
    }
    return () => {
      if (element && root) {
        root.removeChild(element);
      }
    };
  }, [root, element]);

  useEffect(() => {
    const state = getSportsbookRegionCookie();
    if (userLogged) {
      dispatch(setGeoLocationState(state));
    } else if (!state) {
      clearSportsbookRegionCookie();
    }
  }, [userLogged]);

  const handleSelectedState = (state: States) => {
    createSportsbookRegionCookie(state.abbreviation);
    dispatch(setGeoLocationState(state.abbreviation));
    dispatch(setCoreConfigState(state.abbreviation));
    dispatch(setShowStateSelector(false));
    dispatch(resetStateLocationSplashAction());

    // Wait for update body to call this function
    setTimeout(() => {
      triggerGlobalCallback();
    }, 100);

    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  };

  const canCreateModal = showStateSelector && element;

  return canCreateModal
    ? createPortal(
        <StateSelector
          onSelectState={handleSelectedState}
          to={currentPath}
          onClose={onClose}
        />,
        element
      )
    : null;
};

export default StateSelectorScreen;
