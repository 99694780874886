import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { History } from "history";
import { Dispatch, Store } from "redux";
import { connect } from "react-redux";
import { get, isEmpty } from "lodash";
import mediator from "@tvg/mediator";
import parseCapiMessage from "@tvg/utils/capiUtils";
import { Brand } from "@tvg/conf/src/types";
import {
  ErrorMessages,
  Labels
} from "@fdr/atomic-ui/_templates/ErrorPage/types";
import { Modal, ModalType } from "@tvg/design-system";
import UMErrorPage from "@urp/unified-modules/src/components/PageError";
import UMLocationErrorPage from "@urp/unified-modules/src/components/PageError/LocationErrorPage";
import ErrorPage from "@fdr/atomic-ui/_templates/ErrorPage";
import {
  ActionButton,
  ErrorMessage,
  MapImageEnum
} from "@urp/unified-modules/src/components/PageError/types";
import ProcessingOverlay from "@fdr/static-ui/ProcessingOverlay";
import { logout } from "@urp/unified-modules/src/utils/account/logout";
import requestGeolocation, {
  setShowStateSelector
} from "@tvg/sh-geolocation/src/utils/requestGeolocation";
import { checkLocationPermission } from "@tvg/sh-geolocation/src/utils/checkLocationPermission";
import {
  askLocation,
  isAndroid,
  isMobile,
  isXSell,
  onTriggerGeolocation,
  openExternalLink
} from "@tvg/sh-utils/mobileUtils";
import { RegionData } from "@tvg/geolocation";
import tvgConf from "@tvg/conf";
import { LoginModalMediatorEventType } from "@tvg/ts-types/Amplitude";
import { parseJSONCapiMessage } from "@fdr/utils/parseJSONCapiMessage";
import { getIsLogged, getResidenceState } from "@urp/store-selectors";
import { getSession } from "@urp/unified-modules/src/utils/account";
import { triggerGlobalCallback } from "@tvg/sh-utils/callbackUtils";
import pluralize, { isTvg5 } from "@tvg/utils/generalUtils";
import { getGeolocationStatus } from "@tvg/sh-geolocation/src/redux/selectors";
import {
  setGeolocationError,
  setGeolocationStatus
} from "@tvg/sh-geolocation/src/redux/actions";
import {
  GeolocationRejectedErrorMessage,
  GeolocationRejectedErrorModalTitle,
  GeolocationStatus
} from "@tvg/sh-geolocation/src/types";
import { getRejectedErrorTitle } from "@tvg/sh-geolocation/src/utils/getRejectedErrorTitle";
import { openExternalApp } from "@tvg/utils/mediatorUtils";
import type { FixedBannerProps } from "@fdr/atomic-ui/_molecules/MessageV2/types";
import type { ErrorDescription } from "@tvg/ts-types/GeoComply";
import { getDeviceOS } from "../../../apps/frontend-fdm/src/shared/pages/utils/isSafari";
import { initialState } from "./reducer";
import {
  clearSplashErrors,
  errorLocationSplashAction,
  loadingLocationSplashAction,
  resetStateLocationSplashAction,
  setAndroidBlockedState,
  setLocationBehindScenesAction,
  setLocationRequiredAction,
  setLocationStatusAction,
  setStatusLocationUnderFlow
} from "./actions";
import {
  ErrorTypes,
  GhostAccountExceptionFDR,
  IOSForceUpdateFDR,
  LocationStatusTypes,
  LocationUnderFlow,
  SplashType,
  StatusLocationUnderFlow,
  WalkthroughNotFoundMessage
} from "./types";

export type AndroidBlockedStateMessages = {
  [key in Brand]: {
    title: string;
    description: string;
  };
};

type LoginFailedMessage = {
  title?: string | null;
  message?: string | null;
};

type Props = {
  user: {
    userName: string;
    isLogged: boolean;
    homeState: string;
  };
  statesWithLocationRequired: string;
  isOpen: boolean;
  isLoading: boolean;
  splashType: SplashType;
  error: ErrorTypes | null;
  isLocationRequired: boolean;
  isLocationBehindTheScenes: boolean;
  dispatch: Dispatch;
  history: History;
  messages: ErrorMessages;
  labels: Labels;
  geolocation: RegionData;
  exclusionEndDate: string;
  androidBlockedStateMessages: AndroidBlockedStateMessages;
  androidGpsAllowedStates: Array<string>;
  useAndroidGpsAllowedStates: boolean;
  iOSForceUpdateFDR?: IOSForceUpdateFDR;
  locationUnderFlow?: LocationUnderFlow;
  allowedStates: Array<string>;
  ghostAccountException?: GhostAccountExceptionFDR;
  isGhostAccountSplash?: boolean;
  walkthroughNotFoundMessage?: WalkthroughNotFoundMessage;
  globalFDRSupportLink: string;
  loginFailedMessage?: LoginFailedMessage;
  statusLocationUnderFlow?: StatusLocationUnderFlow;
  coreConfigState?: string;
  geoLocationRejectedErrorModalTitle: GeolocationRejectedErrorModalTitle;
  geolocationBannerMsg?: FixedBannerProps;
  geolocationStatus: GeolocationStatus | "PERMISSION_DENIED";
  geoLocationErrorModalTitle: Record<
    GeolocationStatus | "PERMISSION_DENIED",
    string
  >;
  geolocationError: Array<GeolocationRejectedErrorMessage>;
  geolocationErrorRetryable: boolean;
  geoLocationErrorModalTips: Record<
    GeolocationStatus | "PERMISSION_DENIED",
    ErrorDescription
  >;
};

type ExclusionProps = {
  messages: ErrorMessages;
  exclusionEndDate?: string;
};

export const handleLocationStatus = (
  status: LocationStatusTypes,
  dispatch: Dispatch
) => {
  dispatch(setLocationStatusAction(status));
};

export const handleCloseButton = (dispatch: Dispatch) => {
  handleLocationStatus("empty", dispatch);
  dispatch(resetStateLocationSplashAction());
};

export const handleLoading = (dispatch: Dispatch) => {
  dispatch(loadingLocationSplashAction());
};

export const handleAllowLocation = (
  dispatch: Dispatch,
  geolocation?: RegionData,
  allowedStates: Array<string> = [],
  isUserLogged: boolean = false
) => {
  handleLoading(dispatch);

  if (isMobile(tvgConf().product)) {
    if (geolocation) {
      handleLocationStatus("success", dispatch);
    } else {
      askLocation();
    }
    dispatch(resetStateLocationSplashAction());
  } else {
    requestGeolocation({
      dispatch,
      allowedStates,
      isUserLogged
    })
      .then(() => {
        handleLocationStatus("success", dispatch);
        dispatch(setStatusLocationUnderFlow(StatusLocationUnderFlow.ASKED));
      })
      .catch((err: Error) => {
        const errorCode: LocationError = get(err, "message");
        const locationErrorCodes = [
          "PERMISSION_DENIED",
          "POSITION_UNAVAILABLE",
          "TIMEOUT",
          "UNAVAILABLE_GEOLOCATION"
        ];

        if (locationErrorCodes.includes(errorCode)) {
          dispatch(errorLocationSplashAction("LOCATION_ERROR"));
          dispatch(setGeolocationStatus(errorCode));
          dispatch(setGeolocationError(errorCode, [], true));
        } else if (errorCode === "LOCATION_SERVICE_ERROR") {
          if (geolocation?.state) {
            dispatch(setStatusLocationUnderFlow(StatusLocationUnderFlow.ASKED));
          } else {
            dispatch(setShowStateSelector(true));
          }
        } else {
          dispatch(errorLocationSplashAction("WRONG_LOCATION_ERROR"));
        }
      });
  }
};

export const reconfirmLocation = (dispatch: Dispatch, error: ErrorTypes) => {
  if (error === "LOCATION_ERROR") {
    checkLocationPermission();
    handleCloseButton(dispatch);
  } else if (typeof window !== "undefined") {
    window.location.reload();
  }
};
const isAndroidPlatform = getDeviceOS() === "android";
const renderError = ({
  error,
  dispatch,
  labels,
  allowedStates,
  messages,
  loginFailedMessage,
  globalFDRSupportLink,
  isOpen,
  geoLocationRejectedErrorModalTitle,
  geolocationBannerMsg,
  geolocationStatus,
  geoLocationErrorModalTitle,
  geolocationError,
  geolocationErrorRetryable,
  geoLocationErrorModalTips
}: Props) => {
  const isDesk = isTvg5();

  if (error === "LOCATION_ERROR") {
    let modalType: ModalType = "flexible";
    let modalTitle: string = get(messages, "locationError.title", "");
    let imageType = MapImageEnum.ERROR;
    let isGenericError = false;
    let pageTitle = "";
    let pageErrorsTitle: string = get(messages, "locationError.text", "");
    let pageErrorsMessages: ErrorMessage[] = get(
      labels,
      "locationErrors.topics",
      []
    );
    let primaryButton = {
      title: get(labels, "locationErrors.extraButton", ""),
      onClick: () => {
        mediator.base.dispatch({
          type: LoginModalMediatorEventType.LOCATION_MODAL_CTA_CLICKED
        });

        reconfirmLocation(dispatch, error);
      }
    };
    let secondaryButton: ActionButton | undefined;
    let description: ErrorDescription | undefined;

    if (isDesk) {
      modalType = "lightbox";
      modalTitle = get(geolocationBannerMsg, `${geolocationStatus}.text`);

      const errorPageSecondaryButton =
        geolocationStatus === "PERMISSION_DENIED"
          ? {
              title: "Dismiss",
              onClick: () => {
                handleCloseButton(dispatch);
                mediator.base.dispatch(
                  LoginModalMediatorEventType.LOCATION_MODAL_CLOSED
                );
              }
            }
          : undefined;

      imageType =
        geolocationStatus === GeolocationStatus.REJECTED ||
        geolocationStatus === "PERMISSION_DENIED"
          ? MapImageEnum.ERROR
          : MapImageEnum.PIN;

      isGenericError = geolocationStatus === GeolocationStatus.FAILURE;
      pageTitle = get(geoLocationErrorModalTitle, geolocationStatus);
      pageErrorsTitle = `We've found ${pluralize(
        // @ts-ignore
        geolocationError[geolocationStatus]?.length,
        "error"
      )}`;
      pageErrorsMessages = getRejectedErrorTitle(
        geoLocationRejectedErrorModalTitle,
        get(geolocationError, geolocationStatus)
      );
      primaryButton = geolocationErrorRetryable
        ? {
            title:
              geolocationStatus === "PERMISSION_DENIED" ? "Try Again" : "Retry",
            onClick: () => {
              handleCloseButton(dispatch);
              if (
                typeof window !== "undefined" &&
                geolocationStatus === "PERMISSION_DENIED"
              ) {
                mediator.base.dispatch({
                  type: LoginModalMediatorEventType.LOCATION_MODAL_CTA_CLICKED,
                  payload: {
                    linkText: "Try Again",
                    linkUrl: window.location.href
                  }
                });

                if (typeof window !== "undefined") {
                  window.location.reload();
                }
                return;
              }
              onTriggerGeolocation("login");
            }
          }
        : {
            title: "Contact Support",
            onClick: () => {
              handleCloseButton(dispatch);
              openExternalApp(globalFDRSupportLink);
            }
          };
      secondaryButton = errorPageSecondaryButton;

      description = get(
        geoLocationErrorModalTips,
        geolocationStatus,
        geoLocationErrorModalTips.LOCATION_FAILURE
      );
    }

    return (
      <Modal
        type={modalType}
        title={isAndroidPlatform ? "" : modalTitle}
        isOpen={isOpen}
        onClose={() => {
          handleCloseButton(dispatch);
          mediator.base.dispatch({
            type: LoginModalMediatorEventType.LOCATION_MODAL_CLOSED
          });
        }}
        onOpen={() => {
          mediator.base.dispatch({
            type: LoginModalMediatorEventType.LOCATION_MODAL_VIEWED
          });
        }}
        padding={isAndroidPlatform ? 12 : 0}
        hasBorderBottom={false}
        isDarkMode={!isAndroidPlatform}
      >
        {isAndroidPlatform ? (
          <UMLocationErrorPage
            errorTitles={get(messages, "locationError", [])}
            errorMessages={get(labels, "locationErrors.modalMessages", [])}
            allowedStates={allowedStates}
          />
        ) : (
          <UMErrorPage
            imageType={imageType}
            isGenericError={isGenericError}
            title={pageTitle}
            errorsTitle={pageErrorsTitle}
            errorsMessages={pageErrorsMessages}
            primaryButton={primaryButton}
            secondaryButton={secondaryButton}
            description={description}
          />
        )}
      </Modal>
    );
  }

  if (error === "UNAVAILABLE_LOCATION_ERROR") {
    return (
      <ErrorPage
        iconType="warningRed"
        boxType="locationErrors"
        boxLabels={get(labels, "unavailableLocation", {})}
        message={get(messages, "unavailableLocation", {})}
        hideSecondaryButton
        handleButton={() => reconfirmLocation(dispatch, error)}
        handleClose={() => handleCloseButton(dispatch)}
      />
    );
  }

  if (error === "WRONG_LOCATION_ERROR") {
    return (
      <ErrorPage
        iconType="warningRed"
        boxType="locationErrors"
        boxLabels={get(labels, "unavailableLocation", {})}
        message={get(messages, "somethingWentWrongLocation", {})}
        hideSecondaryButton
        handleButton={() => reconfirmLocation(dispatch, error)}
        handleClose={() => handleCloseButton(dispatch)}
      />
    );
  }

  return (
    <Modal
      type={isDesk ? "lightbox" : "flexible"}
      title={loginFailedMessage?.title ?? "An error has occurred"}
      isOpen={isOpen}
      onClose={() => {
        handleCloseButton(dispatch);
        mediator.base.dispatch({
          type: LoginModalMediatorEventType.LOGIN_ERROR_MODAL_CLOSED
        });
      }}
      onOpen={() => {
        mediator.base.dispatch({
          type: LoginModalMediatorEventType.LOGIN_ERROR_MODAL_VIEWED
        });
      }}
      padding={0}
      hasBorderBottom={false}
      isDarkMode
    >
      <UMErrorPage
        imageType={MapImageEnum.LOGIN_ERROR}
        title={loginFailedMessage?.message || "Login page failed to load."}
        primaryButton={{
          title: "Contact Support",
          onClick: () => {
            openExternalLink(globalFDRSupportLink);
            mediator.base.dispatch({
              type: LoginModalMediatorEventType.LOGIN_ERROR_MODAL_CTA_CLICKED,
              payload: {
                linkUrl: globalFDRSupportLink
              }
            });
          }
        }}
      />
    </Modal>
  );
};

export const renderAccountInTimeout = ({
  messages,
  exclusionEndDate = ""
}: ExclusionProps | Props) => {
  const regexEndDateTag = /#EXCLUSION_END_DATE/gi;
  const message = {
    title: get(messages, "rgAccountInTimeout.title", ""),
    text: get(messages, "rgAccountInTimeout.text", "").replace(
      regexEndDateTag,
      `${exclusionEndDate}`
    )
  };

  return (
    <ErrorPage
      iconType="clock"
      showClose={false}
      message={message}
      showLoginBox={false}
    />
  );
};

export const renderAccountInSelfExclusion = ({
  messages
}: ExclusionProps | Props) => (
  <ErrorPage
    iconType="calendar"
    showClose={false}
    message={get(messages, "rgAccountInSelfExclusion", {})}
    showLoginBox={false}
  />
);

export const renderBlockedStateAndroid = (
  messages: AndroidBlockedStateMessages
) => (
  <ErrorPage
    isAndroidBlocked
    androidBlockType="state"
    message={{
      title: get(messages, "fdr.title"),
      text: get(messages, "fdr.description")
    }}
    iconType="calendar"
    showLoginBox={false}
    showClose={false}
  />
);

export const renderBlockedCountry = () => (
  <ErrorPage
    isAndroidBlocked
    androidBlockType="country"
    message={{
      title: "FanDuel Racing is not available outside the US",
      text: "Wagering on FanDuel Racing is not available while abroad. When you return home, come back and try again."
    }}
    boxLabels={{
      primaryButton: "Check my location again"
    }}
    boxType="blockedCountryAndroid"
    iconType="calendar"
    showClose={false}
    handleButton={askLocation}
  />
);

export const renderOutdatedAppVersion = (
  dispatch: Dispatch,
  iOSForceUpdateFDR?: IOSForceUpdateFDR
) => (
  <ErrorPage
    message={{
      title: get(iOSForceUpdateFDR, "title", ""),
      text: get(iOSForceUpdateFDR, "message", "")
    }}
    boxLabels={{
      extraButton: get(iOSForceUpdateFDR, "primaryButtonTitle", "")
    }}
    handleButton={() => {
      if (isAndroid(tvgConf().product)) {
        openExternalLink(
          "https://www.fanduel.com/fdr-android?shortlink=8fdb0c22"
        );
      } else {
        openExternalLink(
          "https://itunes.apple.com/us/app/fanduel-racebook/id1485539253?mt=8&uo=4"
        );
      }
    }}
    boxType="locationErrors"
    iconType="clock"
    hideSecondaryButton
    showClose={
      isAndroid(tvgConf().product)
        ? get(iOSForceUpdateFDR, "dismissableAndroid", false)
        : get(iOSForceUpdateFDR, "dismissable", false)
    }
    handleClose={() => {
      dispatch(resetStateLocationSplashAction());
    }}
  />
);

export const renderAccountInSuspend = ({
  messages
}: ExclusionProps | Props) => (
  <ErrorPage
    iconType="lock"
    showClose={false}
    message={get(messages, "rgAccountInSuspension", {})}
    showLoginBox={false}
  />
);

const forceBlockedStateAndroid = (props: Props) => {
  if (
    props.user.isLogged &&
    !isEmpty(props.androidGpsAllowedStates) &&
    !props.androidGpsAllowedStates.includes(get(props.geolocation, "state")) &&
    props.splashType !== "ANDROID_BLOCKED_STATE"
  ) {
    props.dispatch(setAndroidBlockedState());
  }

  if (
    (!props.user.isLogged ||
      props.androidGpsAllowedStates.includes(
        get(props.geolocation, "state")
      )) &&
    props.splashType === "ANDROID_BLOCKED_STATE"
  ) {
    props.dispatch(clearSplashErrors());
  }
};

const renderLocationSplash = (props: Props) => {
  const {
    splashType,
    dispatch,
    history,
    labels,
    messages,
    geolocation,
    androidBlockedStateMessages,
    iOSForceUpdateFDR,
    allowedStates,
    user,
    ghostAccountException,
    isGhostAccountSplash,
    walkthroughNotFoundMessage
  } = props;

  switch (splashType) {
    case "INVALID_WAGERING_STATE":
      return (
        <ErrorPage
          iconType="warningRed"
          boxType="invalidWageringState"
          boxLabels={get(labels, "invalidWageringState", {})}
          message={get(messages, "invalidWageringState", {})}
          handleButton={() => {
            if (isMobile(tvgConf().product)) {
              handleCloseButton(dispatch);
            }

            openExternalLink("/redirectEngine");
          }}
          handleSecondaryButton={() => {
            history.push("/");
            handleCloseButton(dispatch);
          }}
          handleClose={() => handleCloseButton(dispatch)}
        />
      );
    case "WALKTHROUGH_NOT_FOUND":
      return (
        <ErrorPage
          boxType="simpleSelection"
          boxLabels={{
            primaryButton: get(
              walkthroughNotFoundMessage,
              "primaryButtonTitle",
              ""
            )
          }}
          message={{
            title: get(walkthroughNotFoundMessage, "title", "")
          }}
          iconType="error"
          handleButton={() => {
            handleCloseButton(dispatch);
          }}
          handleClose={() => {
            handleCloseButton(dispatch);
          }}
        />
      );
    case "FDR_GHOST_ACCOUNT_EXCEPTION":
      return (
        isGhostAccountSplash && (
          <ErrorPage
            boxType="needMoreInformation"
            boxLabels={{
              primaryButton: get(
                ghostAccountException,
                "primaryButtonTitle",
                ""
              ),
              secondaryButton: get(
                ghostAccountException,
                "secondaryButtonTitle",
                ""
              )
            }}
            message={{
              title: get(ghostAccountException, "title", ""),
              text: get(ghostAccountException, "message", "")
            }}
            iconType="error"
            handleButton={() => {
              getSession("Normal");
            }}
            handleSecondaryButton={() => {
              logout();
              handleCloseButton(dispatch);
            }}
            handleClose={() => {
              logout();
              handleCloseButton(dispatch);
            }}
          />
        )
      );
    case "TIMEOUT":
      return renderAccountInTimeout(props);
    case "BLOCKED_COUNTRY":
      return renderBlockedCountry();
    case "ANDROID_BLOCKED_STATE":
      return renderBlockedStateAndroid(androidBlockedStateMessages);
    case "SELF_EXCLUSION":
      return renderAccountInSelfExclusion(props);
    case "SUSPEND":
      return renderAccountInSuspend(props);
    case "APP_VERSION_OUTDATED":
      return renderOutdatedAppVersion(dispatch, iOSForceUpdateFDR);
    case "ERROR":
      return renderError(props);
    default:
      return (
        <ErrorPage
          boxType="allowLocationSplash"
          boxLabels={get(labels, "allowLocationSplash", {})}
          message={get(messages, "allowLocationSplash", {})}
          iconType="allow"
          handleButton={() =>
            handleAllowLocation(
              dispatch,
              geolocation,
              allowedStates,
              user.isLogged
            )
          }
          handleSecondaryButton={() => handleCloseButton(dispatch)}
          handleClose={() => handleCloseButton(dispatch)}
        />
      );
  }
};

const renderLoading = (element: HTMLElement, text: string) =>
  createPortal(
    <ProcessingOverlay
      isTransparent
      dotSize="medium"
      text={text}
      isFullScreen
    />,
    element
  );

export const LoadingSplash = ({
  messages,
  isOpen
}: {
  isOpen: boolean;
  messages: ErrorMessages;
}) => {
  const [element, setElement] = useState<HTMLElement>();

  useEffect(() => {
    let root: HTMLElement;

    if (typeof window !== "undefined") {
      const tempElement = document.createElement("div");
      tempElement.setAttribute("id", "test");

      setElement(tempElement);

      root = document.body;
      root.prepend(tempElement);
    }

    return () => {
      if (element) {
        root.removeChild(element);
      }
    };
  }, []);

  return isOpen && element
    ? renderLoading(element, get(messages, "loadingLocationSplash.text", ""))
    : null;
};

export const LocationSplash = (props: Props) => {
  const {
    user,
    isOpen,
    isLoading,
    isLocationRequired,
    isLocationBehindTheScenes,
    locationUnderFlow,
    dispatch,
    messages,
    geolocation,
    allowedStates,
    statusLocationUnderFlow,
    coreConfigState
  } = props;

  const [element, setElement] = useState<Element>();

  /* istanbul ignore next */
  useEffect(() => {
    let root: HTMLElement;

    if (typeof window !== "undefined") {
      const tempElement = document.createElement("div");
      tempElement.setAttribute("id", "test");

      setElement(tempElement);

      root = document.body;
      root.prepend(tempElement);
    }

    return () => {
      if (element) {
        root.removeChild(element);
      }
    };
  }, []);

  useEffect(() => {
    if (
      props.useAndroidGpsAllowedStates &&
      tvgConf().product === "androidwrapper"
    ) {
      forceBlockedStateAndroid(props);
    }

    const isLogged = get(user, "isLogged", false);
    const userLocationRequired = isLogged && !isXSell();

    if (userLocationRequired && !isLocationRequired) {
      dispatch(setLocationRequiredAction(true));
      dispatch(setLocationBehindScenesAction(true));
    }
  }, [user]);

  useEffect(() => {
    if (
      coreConfigState &&
      statusLocationUnderFlow === StatusLocationUnderFlow.ASKED
    ) {
      dispatch(resetStateLocationSplashAction());
      dispatch(setStatusLocationUnderFlow(StatusLocationUnderFlow.COMPLETED));
      triggerGlobalCallback();
    }
  }, [coreConfigState, statusLocationUnderFlow]);

  if (!isOpen) {
    if (isLocationBehindTheScenes || locationUnderFlow) {
      // This is a way to request user geolocation `behind the scenes`
      handleAllowLocation(dispatch, geolocation, allowedStates, user.isLogged);
    }
    return null;
  }

  if (isLoading && user.isLogged) return null;

  if (isLoading) {
    return <LoadingSplash isOpen={isOpen} messages={messages} />;
  }

  return createPortal(renderLocationSplash(props), element!);
};

const mapStateToProps = (store: Store) => ({
  user: {
    isLogged: getIsLogged(store),
    homeState: getResidenceState(store),
    userName: get(store, "userData.user.userName", "")
  },
  statesWithLocationRequired: get(
    store,
    "capi.messages.statesWithLocationRequired",
    '["ca"]'
  ),
  androidGpsAllowedStates: get(
    store,
    "capi.messages.androidGpsAllowedStatesFDR",
    []
  ),
  isOpen: get(store, "locationSplash.isOpen", initialState.isOpen),
  isLoading: get(store, "locationSplash.isLoading", initialState.isLoading),
  isLocationRequired: get(
    store,
    "locationSplash.isLocationRequired",
    initialState.isLocationRequired
  ),
  isLocationBehindTheScenes: get(
    store,
    "locationSplash.isLocationBehindTheScenes",
    initialState.isLocationBehindTheScenes
  ),
  locationUnderFlow: get(store, "locationSplash.locationUnderFlow"),
  splashType: get(store, "locationSplash.splashType", initialState.splashType),
  error: get(store, "locationSplash.error", initialState.error),
  status: get(store, "locationSplash.status", initialState.status),
  exclusionEndDate: get(store, "locationSplash.exclusionEndDate"),
  messages: parseJSONCapiMessage(
    get(store, "capi.messages"),
    "loginErrorMessages"
  ),
  labels: parseJSONCapiMessage(get(store, "capi.messages"), "loginLabels"),
  geolocation: get(store, "geolocation"),
  androidBlockedStateMessages: parseJSONCapiMessage(
    store,
    "capi.messages.androidBlockedStatesMessages"
  ),
  useAndroidGpsAllowedStates: get(
    store,
    "capi.featureToggles.useAndroidGpsAllowedStates",
    false
  ),
  isGhostAccountSplash: get(
    store,
    "capi.featureToggles.enableAccountSplash",
    false
  ),
  iOSForceUpdateFDR: parseCapiMessage(store, "capi.messages.iOSForceUpdateFDR"),
  allowedStates: Object.keys(
    parseJSONCapiMessage(store, "capi.messages.stateSelectorListFdr", {})
  ),
  ghostAccountException: parseJSONCapiMessage(
    store,
    "capi.messages.ghostAccountException",
    {}
  ),
  walkthroughNotFoundMessage: parseJSONCapiMessage(
    store,
    "capi.messages.walkthroughNotFoundMessage",
    {}
  ),
  globalFDRSupportLink: get(store, "capi.messages.globalFDRSupportLink", ""),
  loginFailedMessage: parseJSONCapiMessage(
    store,
    "capi.messages.loginFailedMessage"
  ),
  statusLocationUnderFlow: get(
    store,
    "locationSplash.statusLocationUnderFlow",
    initialState.statusLocationUnderFlow
  ),
  coreConfigState: get(store, "unifiedModules.CoreConfig.state"),
  geolocationError: get(store, "geolocation.geolocationError", {}),
  geolocationBannerMsg: parseJSONCapiMessage(
    store,
    "capi.messages.geoLocationBannerMsg",
    {}
  ),
  geolocationStatus: getGeolocationStatus(store) as GeolocationStatus,
  geoLocationErrorModalTitle: parseJSONCapiMessage(
    store,
    "capi.messages.geoLocationErrorModalTitle",
    {}
  ),
  geoLocationRejectedErrorModalTitle: parseJSONCapiMessage(
    store,
    "capi.messages.geoLocationRejectedErrorModalTitle",
    {}
  ),
  geoLocationErrorModalTips: parseJSONCapiMessage(
    store,
    "capi.messages.geoLocationErrorModalTips",
    {}
  ),
  geolocationErrorRetryable: get(store, "geolocation.isErrorRetryable", false)
});

export default connect(mapStateToProps)(LocationSplash);
